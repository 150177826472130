<script>
import comp from "../base"
// import { mapState } from "vuex"
import { UNIT } from "../../services/enums"
import { format, formatDistanceToNow } from "date-fns"
import { handleDate } from "@/helper/helper"

export default {
  neme: "BrewRecyme",
  props: ["popBrew"],
  components: {
    ...comp,
  },
  setup() {
    return {
      UNIT,
      format,
      formatDistanceToNow,
      handleDate,
    }
  },
  computed: {
    // ...mapState(["types"]),
    ingredients() {
      return []
      // this.popBrew.recipeCopy.build
      //   .filter(item => item.type == ACTION.ADD)
      //   .map(item => item.ingredients)
      //   .flat()
      // .map(item => (item["typeName"] = this.types[item.typeId].name))
    },
  },
}
</script>

<template>
  <!-- {{ popBrew }} -->
  <div class="fs-5 fw-bold">Brew Recyme</div>
  <div class="boxCardSide p-3">
    <List>
      Name :
      <template #right> {{ popBrew.formatCount }} {{ popBrew.recipeName }} </template>
    </List>
    <List>
      Style :
      <template #right>{{ popBrew.styleName }}</template>
    </List>
    <List>
      State :
      <template #right>{{ popBrew.stateName }}</template>
    </List>
    <hr />
    <List v-for="(ingredient, index) in popBrew.ingredients" :key="index">
      {{ ingredient.categoryName }} | {{ ingredient.name }}
      <template #right> {{ ingredient.quantity }} {{ ingredient.unitText }} </template>
    </List>
    <hr />
    <List>
      ABV :
      <template #right>{{ popBrew.recipeCopy.abv }}</template>
    </List>
    <List>
      FG :
      <template #right>{{ popBrew.recipeCopy.fg }}</template>
    </List>
    <List>
      OG :
      <template #right>{{ popBrew.recipeCopy.og }}</template>
    </List>
    <hr />
    <List>
      Tank :
      <template #right>{{ popBrew.tankName }}</template>
    </List>
    <List>
      Fermentation :
      <template #right>{{ popBrew.fermentationTime }} days</template>
    </List>
    <List>
      Quantity :
      <template #right> {{ popBrew.recipeCopy.batchSize }} {{ popBrew.tankUnit }}</template>
    </List>
    <hr />
    <List>
      Created :
      <template #right>
        {{ formatDistanceToNow(popBrew.createdDate, { addSuffix: true }) }} |
        {{ format(popBrew.createdDate, "dd/MM/yyyy") }}
      </template>
    </List>
    <List>
      Created by :
      <template #right>
        {{ popBrew.createdBy }}
      </template>
    </List>
    <List>
      Edited :
      <template #right>
        <span v-if="popBrew.updatedDate">
          {{ formatDistanceToNow(popBrew.updatedDate, { addSuffix: true }) }} |
          {{ format(popBrew.updatedDate, "dd/MM/yyyy") }}
        </span>
        <span v-else>-</span>
      </template>
    </List>
    <List>
      Edit by :
      <template #right>
        {{ popBrew.editedBy }}
      </template>
    </List>
    <List>
      Start :
      <template #right>
        {{ formatDistanceToNow(handleDate(popBrew.start), { addSuffix: true }) }} |
        {{ format(handleDate(popBrew.start), "dd/MM/yyyy") }}
      </template>
    </List>
    <List>
      End :
      <template #right>
        {{ formatDistanceToNow(handleDate(popBrew.end), { addSuffix: true }) }} |
        {{ format(handleDate(popBrew.end), "dd/MM/yyyy") }}
      </template>
    </List>
    <List v-if="popBrew.endEstimated">
      Estimated End :
      <template #right>
        {{ formatDistanceToNow(handleDate(popBrew.endEstimated), { addSuffix: true }) }} |
        {{ format(handleDate(popBrew.endEstimated), "dd/MM/yyyy") }}
      </template>
    </List>
  </div>
</template>
